<template>
  <v-form v-if="item" autocomplete="off">
    <v-row>
        <v-col md=7>

          <v-text-field
            id="nameInput"
            name="name"
            v-model="item.name"
            label="Nome"
            hint="texto"
          ></v-text-field>

          <v-textarea
            id="descriptionInput"
            name="description"
            v-model="item.description"
            label="Descrição"
            hint="texto (opcional)"
          ></v-textarea>
        </v-col>

    </v-row>

    <v-row>
        <v-col>
          <back-button view="small"></back-button>
          <save-button view="big" @save="submit()"></save-button>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BackButton from '../../components/BackButton.vue'
import SaveButton from '../../components/SaveButton.vue'
  const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'CustomTypeForm',
    components: {
      BackButton,
      SaveButton
    },
    props: ['item'],
    methods: {
      submit () {
        EventBus.$emit('custom-type-submitted')
      }
    }
  }
</script>
