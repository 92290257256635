<template>
<v-container fluid>
  <v-row>
    <v-col
      md=4
      sm=12
      xs=12
    >
    <v-card
        class="mx-auto"
    >
        <v-img
          alt=""
          style="display:none"
          class="shrink mr-2"
          contain
          :src="'/static/illustrations/' + image + '.svg'"
          width="100%"
          icon
        />
        <v-card-title>
            <h1 class='title'>Adicionar Tipo de {{title}}</h1>
        </v-card-title>
    </v-card>
    </v-col>

    <v-col
    >
      <div v-if="!$store.state.loading">
        <custom-type-form :item="item"></custom-type-form>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
  import CustomTypeForm from '../../gyra/components/CustomTypeForm.vue'
    const {slug} = require('../../addons/helpers.js')
  const {processType, typeSchema} = require('../../gyra/descriptors.js')
  const {EventBus} = require('../../addons/event-bus.js')

  export default {
    components: {
        CustomTypeForm,
    },
    name: 'AddEnum',
    mounted() {
      const submit = () => {
        const item = processType(this.item)
        typeSchema.validate(item)
          .then((valid) => {
            const p1 = this.$store.dispatch('addEntity', {entity: 'type', item})
            p1.then(r => {
              this.$store.commit('setReady')
              const p2 = this.$store.dispatch('getEntities', {entity: 'type'})
              p2.then(() => {
                this.$store.commit('setReady')
                this.$router.go(-1)
              })
              return r
            })
            return valid
          })
          .catch((err) => {
            EventBus.$emit('notification', err)
            return err
          })
      }
      EventBus.$on('custom-type-submitted', submit);
    },
    beforeDestroy() {
      EventBus.$off('custom-type-submitted');
    },
    computed: {
        'item.value': () => {
            return slug(this.item.name)
        },
        title() {
          const titles = {
            'tag': 'Tag',
            'partner-tag': 'Tag do Parceiro',
            'lawsuit-tag': 'Tag do Caso',
            'scope': 'Escopo',
            'province': 'UF',
            'cost-status': 'Status de custa',
            'cost-type': 'Tipo de Custa',
            'lawsuit-status': 'Status do Caso',
            'lawsuit-legal-status': 'Status Legal do Caso',
            'portfolio': 'Portfólio',
            'property-status': 'Status de Bem',
            'property-type': 'Tipo de Bem',
          }
          return titles[this.$route.params.scope]
        },
    },
    data() {
      return {
        'image': 'Finance',
        item: {
          app: 'iron',
          name: '',
          value: '',
          description: '',
          scope: this.$route.params.scope
        }
      }
    }
  }
</script>